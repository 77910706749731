<template>
  <div style="display: contents">
    <v-alert v-if="outstanding > 0" type="warning" prominent class="text-center">
      This team has an outstanding balance due
      <v-spacer></v-spacer>
      <v-btn @click.stop="isOpen = true" :loading="loading">
        Pay the balance
      </v-btn>
    </v-alert>
    <v-navigation-drawer
      right
      v-model="isOpen"
      fixed temporary
      :width="$vuetify.breakpoint.xs ? 350 : 500"
    >
      <!-- HEADER -->
      <template v-slot:prepend>
      <v-toolbar color="color2 color2Text--text" tile dense>
        <v-toolbar-title>Pay My Balance</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1 color1Text--text"
          fab
          small
          @click.stop="cancel(true)"
        >
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      </template>
      <!-- FOOTER -->
      <template v-slot:append>
        <v-row justify="end">
          <v-btn
            color="color3"
            text x-small
            @click.stop="window = 1"
            v-if="(access.td || access.vbl) && window !== 1"
          >
            Add External Payment
          </v-btn>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row dense>
            <v-col cols="6">
              <v-slide-x-reverse-transition>
                <v-btn
                  v-if="window === 0"
                  color="success"
                  :disabled="!dto"
                  :loading="loading"
                  @click.stop="addToCart"
                  block
                  class="py-9"
                >
                  Add To Cart
                </v-btn>
                <v-btn
                  v-else
                  color="success"
                  :disabled="!xValid"
                  :loading="loading"
                  @click.stop="onAddClick"
                  block
                  class="py-9"
                >
                  Save
                </v-btn>
              </v-slide-x-reverse-transition>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="error white--text"
                @click.stop="cancel"
                :disabled="loading"
                block
                class="py-9"
              >
              Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </template>
      <!-- BODY -->
      <v-card-text>
        <v-container class="pt-10 px-0" v-if="regMtr">
          <v-row>
            <v-col cols="9">
              <div class="body-1 black--text">{{ regMtr.description }}</div>
              <div class="body-2 grey--text">{{ regMtr.dt | localDt }}</div>
            </v-col>
            <v-col cols="3" class="money black--text text-right">
              {{amountDue | usDollars}}
            </v-col>
          </v-row>
          <v-row v-for="(payment, i) in payments" :key="i">
            <v-col cols="9">
              <div class="body-1 black--text">{{payment.type}}{{ payment.description ? ` | ${payment.description}` : '' }}</div>
              <div class="body-2 grey--text">{{ payment.dt | localDt }}</div>
            </v-col>
            <v-col cols="3" class="money black--text text-right">
              {{-payment.amount | usDollars}}
            </v-col>
          </v-row>
          <v-divider inset></v-divider>
          <v-row>
            <v-col cols="9" class="text-right">
              <div class="body-1 black--text">Outstanding Balance:</div>
            </v-col>
            <v-col cols="3" class="money black--text text-right">
              {{outstanding | usDollars}}
            </v-col>
          </v-row>
          <!-- PAYMENT -->
          <v-window v-model="window" touchless>
            <v-window-item :key="0">
              <v-row class="mt-4">
                <v-col cols="12" class="text-center title">
                  How much do you want to pay?
                </v-col>
                <v-col cols="12">
                  <v-btn
                    :color="payFull ? 'success' : null"
                    block
                    outlined
                    style="min-height: 60px"
                    class="justify-space-between"
                    @click.stop="payFullClick"
                    :disabled="loading"
                  >
                    <div>{{outstanding | usDollars}}</div>
                    <div>
                      Full Balance
                      <v-fab-transition>
                        <v-icon v-if="payFull">fas fa-check-circle</v-icon>
                      </v-fab-transition>
                    </div>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    label="Other Amount"
                    v-model.number="amount"
                    type="number" min="0"
                    @blur="formatOther"
                    color="success"
                    :success="amount > 0"
                    :append-icon="amount > 0 ? 'fas fa-check-circle' : null"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-window-item>
            <v-window-item :key="1">
              <v-card color="grey lighten-4 my-3">
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="12" class="text-center">
                      <h3>Add External Payment*</h3>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Amount"
                        type="number"
                        color="color3"
                        :disabled="loading"
                        v-model="xAmount"
                        prepend-icon="fas fa-dollar-sign"
                        @blur="formatCurrency"
                      ></v-text-field>
                      <v-combobox
                        v-model="xSource"
                        :items="['Cash', 'Cash App', 'Venmo', 'PayPal', 'Zelle']"
                        label="Source"
                        color="color3"
                        :disabled="loading"
                        item-color="color3"
                      ></v-combobox>
                      <v-textarea
                        label="Notes"
                        v-model="xNotes"
                        color="color3"
                        :disabled="loading"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" class="text-right caption">
                      *For you record keeping purposes only
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-window-item>
          </v-window>
        </v-container>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CartItem from '@/classes/CartItem'
import * as actions from '@/store/ActionTypes'

export default {
  props: ['team', 'credentials', 'access'],
  data () {
    return {
      isOpen: false,
      loading: false,
      payFull: false,
      amount: null,
      moneyTrail: [],
      window: 0,
      xAmount: null,
      xNotes: null,
      xSource: null
    }
  },
  computed: {
    ...mapGetters(['user', 'getTeamToken']),
    teamToken () {
      return this.getTeamToken(this.team.id, this.$route)
    },
    amountDue () {
      return (this.moneyTrail.filter(f => f.type !== 'Payment').reduce((a, c) => {
        return a + c.amount
      }, 0)).toFixed(2)
    },
    amountPaid () {
      return (this.moneyTrail.filter(f => f.type === 'Payment').reduce((a, c) => {
        return a + c.amount
      }, 0)).toFixed(2)
    },
    outstanding () {
      return this.amountDue - this.amountPaid
    },
    regMtr () {
      return this.moneyTrail.find(f => f.parent)
    },
    payments () {
      return this.moneyTrail.filter(f => f.type === 'Payment')
    },
    dto () {
      return this.payFull | this.amount ? {
        mtr: this.regMtr,
        amount: this.payFull ? this.outstanding : this.amount
      } : false
    },
    xValid () {
      return !!(this.xAmount && this.xSource)
    },
    xDto () {
      return {
        shoppingBagItemId: 0,
        amount: this.xAmount,
        source: this.xSource,
        notes: this.xNotes,
        teamId: this.team.id
      }
    }
  },
  methods: {
    getBalance () {
      this.loading = true
      this.$VBL.team.balance(this.team.id, this.teamToken, this.credentials)
        .then(r => { this.moneyTrail = r.data })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    addToCart () {
      if (this.dto) {
        const item = new CartItem()
        item.newSplitPayment(this.dto, this.regMtr.organization)
        this.$store.dispatch(actions.ADD_CART_ITEM, item)
        this.$router.push('/checkout')
      }
    },
    cancel () {
      if (this.window === 0) {
        this.isOpen = false
      } else {
        this.window = 0
        this.reset()
      }
    },
    open () {
      this.isOpen = true
    },
    update () {
      this.getBalance()
    },
    payFullClick () {
      this.payFull = !this.payFull
      if (this.payFull) {
        this.amount = null
      }
    },
    reset () {
      this.xAmount = null
      this.xSource = null
      this.xNotes = null
    },
    onAddClick () {
      this.postExternalPayment(this.xDto)
    },
    postExternalPayment (dto) {
      this.loading = true
      this.$VBL.cart.postExternalPayment(dto || this.xDto)
        .then(r => {
          this.cancel()
          this.$emit('update')
        })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    formatOther () {
      const fee = parseFloat(Math.abs(this.amount))
      this.amount = isNaN(fee) ? null : fee.toFixed(2)
    },
    formatCurrency () {
      const fee = parseFloat(Math.abs(this.xAmount))
      this.xAmount = isNaN(fee) ? null : fee.toFixed(2)
    }
  },
  watch: {
    amount: function (v) {
      if (v) {
        this.payFull = false
      }
    }
  },
  mounted () {
    this.getBalance()
  }
}
</script>

<style scoped>
.money {
  font-size: 1.5em !important;
}
</style>
